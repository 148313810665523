import React from "react";
import styled from "styled-components";
import TrustBox from "./TrustBox";
import IconBlack from "../../assets/icons/snaptrip-tp-black.svg";

const Background = styled.div`
  display: block;
  background: #EEEEEE;
  overflow: auto;
`;

const TrustPilotItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0 1rem;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    padding: 1rem 0;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  @media only screen and (min-width: 1024px) {
    width: 40%;
    flex-direction: row;
  }
`;

const Line = styled.div`
  display: block;
  width: 90%;
  height: 2px;
  margin: 1rem auto;
  background: #9B9B9B;
  
  @media only screen and (min-width: 1024px) {
    width: 2px;
    height: 6.25em;
    margin: auto 0;
    background: #9B9B9B;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  a {
    text-decoration: none;
  }

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Copy = styled.div`
  margin: 1rem 0;
  color: var(--black);
  
  h3 {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.4;
    margin: 0;
  }
  
  @media only screen and (min-width: 1024px) {
    text-align: center;
  }
`;

const Icon = styled.img`
  display: block;
  height: 5.125rem;
  width: 5.5rem;

  @media only screen and (min-width: 1024px) {
    margin: 0 1.5rem 0 3.5rem;
  }
`;

const TrustPilot = () => {
  return (
    <Background>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-1 hidden-m"/>
            <div className="col-10">
              <TrustPilotItems>
                <Box>
                  <TrustBox/>
                </Box>
                <Line/>
                <Box>
                  <Content>
                    <a href="https://www.snaptrip.com/?utm_source=HTH&utm_medium=affiliate-site" target="_blank"
                       rel="noreferrer">
                      <Icon src={IconBlack} alt="Snaptrip"/>
                    </a>
                    <Copy>
                      <h3>Trusted by our customers</h3>
                      <p>As part of the Snaptrip family</p>
                    </Copy>
                  </Content>
                </Box>
              </TrustPilotItems>
            </div>
          </div>
        </div>
      </section>
    </Background>
  );
};
export default TrustPilot;